import React from "react";
import { NewTheme } from "../Theme/Theme";
import { Button } from "primereact/button";
import { CreateClientButton } from "../Users/Overview/Styled";

export default function Marquee(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 40,
      }}
    >
      <marquee scrollamount={5}>
        {props.marquee?.length > 0 ? (
          <div style={{ display: "flex", height: 20 }}>
            {props.marquee?.map((i, t) => (
              <div style={{ color: "black", marginLeft: 20 }}>
                {t + 1}.
                <span style={{ color: NewTheme.MainColor, fontWeight: "bold" }}>
                  {i.news_type}
                </span>{" "}
                <span style={{ color: "black", fontWeight: "bold" }}>
                  {i.news}
                </span>
              </div>
            ))}
          </div>
        ) : null}
      </marquee>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: 140,
        }}
      >
        <CreateClientButton
          active={true}
          color={NewTheme.MainColor}
          onClick={() => props.showLiveSummaryModal()}
        >
          {"LIVE SUMMARY"}
        </CreateClientButton>
      </div>
    </div>
  );
}
